import React from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { useThemeStyles } from '../../utils/useThemeStyles';
// eslint-disable-next-line no-unused-vars
import { CollapseProps } from 'antd/lib/collapse';

interface ICollapseProps extends CollapseProps {
  className?: string;
}

export const { Panel } = AntdCollapse;

export const Collapse: React.FC<ICollapseProps> = (props) => {
  const classes = useThemeStyles('Collapse');

  return <AntdCollapse {...props} className={`${props.className} ${classes.collapse} `} />;
};
