import { INTEGRATIONS } from '../config';

class YandexAnalyticsDriver {
  constructor() {
    this.id = (INTEGRATIONS as Record<string, any>)?.yandexMetrika?.id;
    this.goals = (INTEGRATIONS as Record<string, any>)?.yandexMetrika?.goals
      ? (INTEGRATIONS as Record<string, any>).yandexMetrika.goals
      : {};
  }
  id;
  goals;
  send(event: string) {
    if (typeof (window as any).ym !== 'function' || !this.id) return;
    if (!this.goals[event]) return;
    (window as any).ym(this.id, 'reachGoal', this.goals[event].event);
  }
}

export const useYandexMetrika = () => {
  return new YandexAnalyticsDriver();
};
