import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import { useLegalEntityInfo } from '../../queries/useLegalEntityInfo';
import { phoneParsed } from '../../helpers';

export const DisclaimerWidget: React.FC = () => {
  const classes = useThemeStyles('DisclaimerWidget');
  const { tAsArray, keyExists } = useTranslation();

  const legalEntityQuery = useLegalEntityInfo();

  const phone = phoneParsed(legalEntityQuery?.data?.telephone);

  return (
    <React.Fragment>
      {keyExists('widgets:disclaimer') && (
        <div className={classes.disclaimerWrapper}>
          <span className={classes.disclaimer}>
            {tAsArray('widgets:disclaimer', { phone }).map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};
