import React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormItem, IColumnProps } from '../FormItem';
import { Field } from 'formik';
import { InputProps } from 'antd/lib/input';
import { Input as AntdInput } from 'antd';

interface IInputProps extends InputProps, IColumnProps {
  className?: string;
  label?: string;
  initialValue?: any;
  onReset?: () => void;
}

// eslint-disable-next-line react/display-name
export const Input = React.memo((props: IInputProps) => {
  const classes = useThemeStyles('Form/Input');
  const [inputValue, setInputValue] = React.useState<string | undefined>(undefined);

  const size = props.size ? props.size : 'large';
  const placeholder = props.placeholder ? props.placeholder : undefined;

  const validateRequired = (value) => {
    return !value ? 'required' : undefined;
  };

  return (
    <Field name={props.name} validate={props.required ? validateRequired : null}>
      {({ form, field, meta }) => {
        const initialValue = props.initialValue !== undefined ? props.initialValue : meta.initialValue;

        //setting an initial value
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          if (props.initialValue !== undefined) form.setFieldValue(field.name, initialValue);
          setInputValue(initialValue);
        }, []);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          if (field.value !== inputValue || field.value !== undefined) {
            setInputValue(field.value);
          }
        }, [field.value]);

        return (
          <FormItem {...props} meta={meta}>
            {!props.disabled && (
              <AntdInput
                id={field.name}
                name={field.name}
                size={size}
                defaultValue={initialValue}
                className={classes.input}
                value={inputValue}
                placeholder={placeholder}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onBlur={async () => {
                  await form.setFieldValue(field.name, inputValue);
                  await form.setFieldTouched(field.name, true);
                }}
                disabled={props.disabled}
              />
            )}
            {!!props.disabled && (
              <div id={field.name} className={`ant-input ant-input-lg disabled static ${classes.input}`}>
                {inputValue}
              </div>
            )}
          </FormItem>
        );
      }}
    </Field>
  );
});
