import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import Link from 'next/link';
import { Button } from '../../Button';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { SettingsType } from '@brainysoft/lk-components';
import { useSetting } from '../../../utils/useSetting';
import { useTranslation } from '../../../utils/useTranslation';

const interactionInitUrl = '/contracts/sign/init';

export const InteractionSignOffer: React.FC<IActiveCreditStatus> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const iconSize = 60;
  const signOfferLimit = useSetting(SettingsType.SIGN_OFFER_LIMIT);

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.header}>{t('active:statuses.signOffer.header')}</h2>
      {signOfferLimit && <div>{t('active:statuses.signOffer.headerSignature', { days: signOfferLimit })}</div>}
      <ResultIcon result={ResultType.WAITING} size={iconSize} />
      <Link href={interactionInitUrl} passHref>
        <Button size={'large'} type={'primary'} className={classes.homeButton}>
          {t('active:statuses.signOffer.more')}
        </Button>
      </Link>
    </div>
  );
};
