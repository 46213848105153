import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { QueryClient } from 'react-query/core';
import { ONE_HOUR_STALE } from '../const';
import { ClientT } from '@brainysoft/lk-components';
import { clientService } from '@brainysoft/lk-components';

export const useClient: (options?: Record<string, unknown>) => UseQueryResult<ClientT> = (
  options = { staleTime: ONE_HOUR_STALE }
) => {
  return useQuery(
    ['client'],
    () => {
      return clientService.getClient() as Promise<ClientT>;
    },
    options
  );
};

export const refreshClient: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries(['client']);
};
