import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const NoData = () => {
  const { t } = useTranslation();

  return (
    <div className={``} style={{ textAlign: 'center' }}>
      {t('noData')}
    </div>
  );
};
