const colors = require('./theme_colors');

module.exports = {
  logo: 'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/logos/logotype_mono.svg',
  logoInverted: 'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/logos/logotype_mono.svg',
  logoAuth: 'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/logos/logotype_day.svg',
  logoRegistration: 'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/logos/logotype_mono.svg',
  logoFooter:
    'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/logos/logotype_night_white.svg',
  authBackground:
    'https://cdn.brainysoft.ru/lk/tenants/df528a75-a047-48f9-9f90-528c2976cb64/images/background_text.jpg',
  maxWidth: '1600px',
  fontFamily:
    '"Clear Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  topBarHeight: '108px',
  topBarHeightMobile: '80px',
  generalBorder: `1px solid ${colors.greyLight}`,
  generalBorderLight: `1px solid ${colors.greyLighter}`,
  generalBorderRadius: '6px',
  generalBorderRadiusLarge: '9px',
  generalTransition: 'all 0.3s ease',
  generalTableBorderRadius: '0.5rem',
  loaderBarColor: colors.primary,
  fontSizeBase: '14px',
  fontSizeLarge: '16px',
  fontSizeSmall: '13px',
  inputBorderColor: colors.primary,
};
