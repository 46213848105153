import React from 'react';
import { useClient } from '../../queries/useClient';
import { AppContext } from '@brainysoft/lk-components';
import { useAvatar } from '../../queries/useAvatar';
import { getCustomComponents } from '../../utils/getCustomComponents';

export const ProfileMenuContainer: React.FC = () => {
  const clientQuery = useClient();
  const avatarQuery = useAvatar();

  const client = clientQuery.data;
  const avatar = avatarQuery.data;
  const ProfileMenu = getCustomComponents('ProfileMenu');
  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  return <ProfileMenu client={client} avatar={avatar} mode={mode} />;
};
