import React from 'react';
import { ResultType } from '@brainysoft/lk-components';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface IProps {
  result: ResultType;
  size?: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge' | number;
  strokeWidth?: number;
}

export const namedSizes = {
  small: 16,
  medium: 24,
  large: 52,
  xLarge: 72,
  xxLarge: 96,
};

export const ResultIcon: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ResultIcon');
  const actualSize = props.size ? namedSizes[props.size] || props.size : 'large';
  const style = { width: actualSize, height: actualSize };
  const pathStyle = { strokeWidth: props.strokeWidth };

  return (
    <div className={classes.resultIconWrapper}>
      {props.result === ResultType.SUCCESS && (
        <svg className={classes.checkmark} xmlns='http://www.w3.org/2000/svg' style={style} viewBox='0 0 52 52'>
          <circle className={classes.checkmark__circle} style={pathStyle} cx='26' cy='26' r='25' fill='none' />
          <path className={classes.checkmark__check} style={pathStyle} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
        </svg>
      )}
      {props.result === ResultType.WAITING && (
        <svg className='clock' style={style} viewBox='0 0 52 52'>
          <circle fill='none' className={classes.clock__circle} cx='26' cy='26' r='25' />
          <line fill='none' className={classes.clock__minute} strokeLinecap='round' x1='26' y1='26' x2='26' y2='8' />
          <line fill='none' className={classes.clock__hour} strokeLinecap='round' x1='36' y1='32' x2='26' y2='26' />
        </svg>
      )}
      {props.result === ResultType.FAIL && (
        <svg className='close' style={style} viewBox='0 0 52 52'>
          <circle fill='none' className={classes.close__circle} cx='26' cy='26' r='25' />
          <line fill='none' className={classes.close__line} strokeLinecap='round' x1='14' y1='38' x2='38' y2='14' />
          <line fill='none' className={classes.close__line} strokeLinecap='round' x1='38' y1='38' x2='14' y2='14' />
        </svg>
      )}
      {props.result === ResultType.NO_STATUS && (
        <svg className='no_status' style={style} viewBox='0 0 52 52'>
          <circle fill='none' className={classes.no_status__circle} cx='26' cy='26' r='25' />
          <line fill='none' className={classes.no_status__line} strokeLinecap='round' x1='26' y1='26' x2='26' y2='8' />
          <line fill='none' className={classes.no_status__line} strokeLinecap='round' x1='36' y1='32' x2='26' y2='26' />
        </svg>
      )}
    </div>
  );
};
