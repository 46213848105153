import { SCREEN_SIZE } from '../const';
import { DisplayModeType } from '@brainysoft/lk-components';

export default function device(globalWindow) {
  if (globalWindow.innerWidth <= SCREEN_SIZE.MOBILE) {
    return DisplayModeType.MOBILE;
  } else if (globalWindow.innerWidth <= SCREEN_SIZE.TABLET) {
    return DisplayModeType.TABLET;
  } else {
    return DisplayModeType.DESKTOP;
  }
}
