import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';

export const MessageWidget: React.FC = () => {
  const classes = useThemeStyles('MessageWidget');
  const { t, keyExists } = useTranslation();

  return (
    <React.Fragment>
      {keyExists('widgets:message') && (
        <div className={classes.messageWrapper}>
          <span className={classes.message}>{t('widgets:message')}</span>
        </div>
      )}
    </React.Fragment>
  );
};
