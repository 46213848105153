import * as React from 'react';
// eslint-disable-next-line no-unused-vars
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import FileWordOutlined from '@ant-design/icons/FileWordOutlined';
import FileExcelOutlined from '@ant-design/icons/FileExcelOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import FilterOutlined from '@ant-design/icons/FilterOutlined';
import CreditCardOutlined from '@ant-design/icons/CreditCardOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';

export type IconType =
  | 'arrow-left'
  | 'arrow-right'
  | 'loading'
  | 'plus'
  | 'file-pdf'
  | 'file-word'
  | 'file-excel'
  | 'download'
  | 'delete'
  | 'check-circle'
  | 'close-circle'
  | 'environment'
  | 'phone'
  | 'filter'
  | 'credit-card'
  | 'edit';

interface IIcon extends AntdIconProps {
  type: IconType;
  theme?: 'filled';
}

export const Icon: React.FC<IIcon> = (props) => {
  switch (props.type) {
    case 'arrow-left':
      return <ArrowLeftOutlined />;
    case 'arrow-right':
      return <ArrowRightOutlined />;
    case 'loading':
      return <LoadingOutlined />;
    case 'plus':
      return <PlusOutlined />;
    case 'file-pdf':
      return <FilePdfOutlined />;
    case 'file-word':
      return <FileWordOutlined />;
    case 'file-excel':
      return <FileExcelOutlined />;
    case 'download':
      return <DownloadOutlined />;
    case 'delete':
      return <DeleteOutlined />;
    case 'check-circle':
      return props.theme ? <CheckCircleFilled /> : <CheckCircleOutlined />;
    case 'close-circle':
      return props.theme ? <CloseCircleFilled /> : <CloseCircleOutlined />;
    case 'environment':
      return <EnvironmentOutlined />;
    case 'phone':
      return <PhoneOutlined />;
    case 'filter':
      return <FilterOutlined />;
    case 'credit-card':
      return <CreditCardOutlined />;
    case 'edit':
      return <EditOutlined />;
  }

  return <div>No icon for type {props.type}</div>;
};
