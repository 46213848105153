import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Divider } from 'antd';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { MaskedInput } from '../../../packages/Formik/Components/MaskedInput';
import { useTranslation } from '../../../utils/useTranslation';
import cls from 'classnames';
import { StatefulProcessActions } from '../../StatefulProcess/StatefulProcessActions';
import { IStatefulProcessRenderer } from '../../StatefulProcess/AStatefulProcess';

export const RegularRepaymentAmount: React.FC<IStatefulProcessRenderer> = (props) => {
  const classes = useThemeStyles('RepaymentPage');
  const { t } = useTranslation();
  const formRef = React.useRef<any>();

  const { state, goForward, isMutating, processType } = props;

  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);

  const maxAmount = state.data.maxAmount;

  const onSubmit = async (values, actions) => {
    if (Number(values.amount) < 1) {
      actions.setFieldError('amount', 'amount_less_then_min_value');
      return;
    }
    if (Number(values.amount) > Number(maxAmount)) {
      actions.setFieldError('amount', 'amount_greater_then_max_value');
      return;
    }

    setButtonsDisabled(true);
    await goForward({
      ...state.data,
      amount: values.amount,
    });
    setButtonsDisabled(false);
  };

  return (
    <React.Fragment>
      <div className={cls(classes.repaymentContentWrapper, classes.wrapper)}>
        <h2 className={classes.header}>{t(`repayments:${processType}.header`)}</h2>
        <div className={classes.amountWrapper}>
          <Formik enableReinitialize={true} initialValues={{ amount: 0 }} onSubmit={onSubmit} innerRef={formRef as any}>
            {(formik: FormikProps<any>) => {
              return (
                <FormikForm formik={formik} onSubmit={formik.handleSubmit}>
                  <div className='field'>
                    <MaskedInput
                      name='amount'
                      mask='currency'
                      label={t(`repayments:${processType}.amount`)}
                      placeholder={t(`repayments:${processType}.placeholderAmount`)}
                      required={true}
                    />
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </div>
      </div>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={state.transitions?.back}
        onClickForward={() => {
          formRef.current.submitForm();
        }}
        textForward={t(`repayments:${processType}.forward`)}
        isForwardDisabled={buttonsDisabled}
        isMutating={isMutating}
      />
    </React.Fragment>
  );
};
