import jwtDecode from 'jwt-decode';
import { PHONE_MASK } from '@brainysoft/lk-custom/config';

export const formatNumber = (unformattedNumber = 0) => {
  let result = '';
  const numberSplit = (unformattedNumber + '').split('.');
  result = numberSplit[0];
  if (numberSplit[1]) {
    if (numberSplit[1].length === 1) {
      numberSplit[1] += '0';
    }
    result += ',' + numberSplit[1].substr(0, 2);
  } else {
    result += ',' + '00';
  }
  return result;
};

export const isTokenExpire = (token: string | null) => {
  if (!token) {
    return true;
  }
  try {
    const jwtDecoded = jwtDecode(token);
    const now = Date.now();
    const expire = (jwtDecoded as any).exp * 1000;
    if (now - expire > 0) {
      return true;
    }
    return false;
  } catch (err) {
    return true;
  }
};

export const findColorbyStatus = (list: any[], status: string) => {
  return list.find((el) => el.status === status).color;
};

export const phoneParsed: (phone: string | undefined, mask?: string) => string = (phone, mask) => {
  const maskString = mask ? mask : PHONE_MASK ? PHONE_MASK : '+7';
  if (!phone) return '';

  const phoneWithoutPrefix = phone.slice(1);
  if (maskString.length <= 2)
    return maskString + ' (' + phone.slice(1, 4) + ') ' + phone.slice(4, 7) + ' ' + phone.slice(7, phone.length);

  let counter = 0;
  return maskString.replace(/0/g, () => {
    return phoneWithoutPrefix[counter++] ?? '0';
  });
};

export const phoneRaw = (telephone: string) => {
  return telephone.match(/\d/g).join('');
};
