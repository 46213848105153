import * as React from 'react';
import { MainMenuItem } from '../MainMenuItem';
import { AppContext, DisplayModeType, InterfaceModules } from '@brainysoft/lk-components';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { HEADER_INVERTED } from '../../config';
import { useInterfaceModuleSetting } from '../../utils/useInterfaceModuleSetting';
import { ProfileMenuContainer } from '../../containers/ProfileMenu';
import { useTranslation } from '../../utils/useTranslation';

export const Menu: React.FC = () => {
  const { state } = AppContext.useContainer();
  const { t } = useTranslation();
  const classes = useThemeStyles('Header');

  const mode = state.displayMode;
  const activePage = state.activePage;

  const invertedClass = HEADER_INVERTED ? 'inverted' : '';

  const loanAppsPageSettings = useInterfaceModuleSetting(InterfaceModules.LOAN_APPS_PAGE) ?? { active: true };
  const contractsPageSettings = useInterfaceModuleSetting(InterfaceModules.CONTRACTS_PAGE) ?? { active: true };
  const paymentsPageSettings = useInterfaceModuleSetting(InterfaceModules.PAYMENTS_PAGE) ?? { active: true };
  const documentPageSettings = useInterfaceModuleSetting(InterfaceModules.DOCUMENTS_PAGE) ?? { active: true };
  const servicesButton = useInterfaceModuleSetting(InterfaceModules.SERVICES_BUTTON) ?? { active: false };

  const [opened, setOpened] = React.useState(false);

  const toggleBurgerMenu = () => {
    setOpened(!opened);
  };

  return (
    <React.Fragment>
      {mode === DisplayModeType.DESKTOP && (
        <div className={`${classes.menuWrapper} ${invertedClass}`}>
          {/*<MainMenuItem href={'/'} itemText={t('menuMainPage')} />*/}
          {!!servicesButton?.active && servicesButton?.link && (
            <MainMenuItem href={servicesButton?.link as string} itemText={t('header:services')} external={true} />
          )}
          {!!loanAppsPageSettings?.active && (
            <MainMenuItem href={'/loan-app'} itemText={t('header:loans')} active={activePage === 'loan-app'} />
          )}
          {!!contractsPageSettings?.active && (
            <MainMenuItem href={'/contracts'} itemText={t('header:contracts')} active={activePage === 'contracts'} />
          )}
          {!!paymentsPageSettings?.active && (
            <MainMenuItem href={'/payments'} itemText={t('header:payments')} active={activePage === 'payments'} />
          )}
          {!!documentPageSettings?.active && (
            <MainMenuItem href={'/documents'} itemText={t('header:docs')} active={activePage === 'documents'} />
          )}
          <ProfileMenuContainer />
        </div>
      )}
      {mode !== DisplayModeType.DESKTOP && (
        <React.Fragment>
          <div className={classes.burgerMenuBtnWrapper} onClick={toggleBurgerMenu}>
            <div className={[classes.burgerMenuBtn, invertedClass, opened ? 'active' : null].join(' ')} />
          </div>
          <div className={[classes.offcanvasMenu, invertedClass, opened ? 'active' : null].join(' ')}>
            <div className={classes.closeMenuBtnWrapper} onClick={toggleBurgerMenu}>
              <span className={classes.closeMenuBtn} />
            </div>
            <div className={classes.items}>
              {!!servicesButton?.active && servicesButton?.link && (
                <MainMenuItem
                  href={servicesButton?.link as string}
                  itemText={t('header:services')}
                  offcanvas={true}
                  external={true}
                />
              )}
              {!!loanAppsPageSettings?.active && (
                <MainMenuItem
                  href={'/loan-app'}
                  itemText={t('header:loans')}
                  offcanvas={true}
                  active={activePage === 'loan-app'}
                />
              )}
              {!!contractsPageSettings?.active && (
                <MainMenuItem
                  href={'/contracts'}
                  itemText={t('header:contracts')}
                  offcanvas={true}
                  active={activePage === 'contracts'}
                />
              )}
              {!!paymentsPageSettings?.active && (
                <MainMenuItem
                  href={'/payments'}
                  itemText={t('header:payments')}
                  offcanvas={true}
                  active={activePage === 'payments'}
                />
              )}
              {!!documentPageSettings?.active && (
                <MainMenuItem
                  href={'/documents'}
                  itemText={t('header:docs')}
                  offcanvas={true}
                  active={activePage === 'documents'}
                />
              )}
              <ProfileMenuContainer />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
