import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';
import { ONE_HOUR_STALE } from '../const';
// eslint-disable-next-line no-unused-vars
import { ClientT } from '@brainysoft/lk-components';
import { clientService } from '@brainysoft/lk-components';
import { AvatarT } from '../../../../libs/lk-components/src/interfaces/src/avatar';

export const useAvatar: (options?: Record<string, unknown>) => UseQueryResult<AvatarT> = (
  options = { staleTime: ONE_HOUR_STALE }
) => {
  return useQuery(
    ['avatar'],
    () => {
      return clientService.getAvatar() as Promise<AvatarT>;
    },
    options
  );
};
