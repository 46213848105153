/* eslint-disable no-useless-escape */
import { checkService, processService } from '@brainysoft/lk-components';
import { trans } from '../../../../utils/useTranslation';
import * as config from '@brainysoft/lk-custom/config';
import { debounce } from 'lodash';

const currencyCode = (config as any)?.CURRENCY;
const currencyText = trans(`masks:${currencyCode}.currency`);
const currencyMonthlyText = trans(`masks:${currencyCode}.currencyMonthly`);

const DEBOUNCE_TIMEOUT = 500;

const intMaskOptions = {
  mask: Number,
  scale: 0,
  thousandsSeparator: '',
};

const intWithPlaceholder = {
  mask: [
    { mask: '' },
    {
      mask: Number,
      scale: 0,
      thousandsSeparator: '',
    },
  ],
};

const intWithZero = {
  mask: Number,
  scale: 0,
  normalizeZeros: false,
  thousandsSeparator: '',
};

const intRequired = {
  mask: Number,
  scale: 0,
  thousandsSeparator: '',
  maskValidator: (value: string) => {
    return isNaN(Number(value)) || !Number(value) ? 'required' : undefined;
  },
};

const intRequiredValidZero = {
  mask: Number,
  scale: 0,
  thousandsSeparator: '',
  maskValidator: (value: string) => {
    return isNaN(Number(value)) ? 'required' : undefined;
  },
};

const floatMaskOptions = {
  mask: Number,
  radix: '.',
  mapToRadix: [','],
  scale: 2,
  thousandsSeparator: ' ',
  padFractionalZeros: true,
  placeholder: '0.00',
};

const floatUnlimitedMaskOptions = {
  mask: Number,
  radix: '.',
  mapToRadix: [','],
  scale: 20,
  thousandsSeparator: ' ',
};

const emailMask = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9\-_]+\.[a-zA-Z0-9\-_]{2,}(\.[a-zA-Z0-9\-_]{2,})*$/;

const emailOptions = {
  mask: /[a-zA-Z\-!_#\.@\d]+$/,
  lazy: false, // make placeholder always visible
  maskValidator: (value) => {
    if (!emailMask.test(value)) return 'wrong_email_format';

    return undefined;
  },
};

const emailUnique = {
  mask: /[a-zA-Z\-!_#\.@\d]+$/,
  lazy: false, // make placeholder always visible
  maskValidator: async (value) => {
    if (!emailMask.test(value)) return 'wrong_email_format';

    const res = await checkService.checkEmail(value);
    if (!res?.isUnique) return 'email_is_not_unique';

    return undefined;
  },
};

const currencyMaskOptions = {
  mask: `QTY ${currencyText}`,
  lazy: false, // make placeholder always visible
  placeholder: '0.00',

  blocks: {
    QTY: {
      mask: Number,
      radix: '.',
      mapToRadix: [','],
      scale: 2,
      thousandsSeparator: ' ',
      unmask: false,
      padFractionalZeros: true,
      normalizeZeros: false,
      placeholder: '0.00',
    },
  },
};

const currencyIntMaskOptions = {
  mask: `QTY ${currencyText}`,
  lazy: false, // make placeholder always visible
  placeholder: '0',

  blocks: {
    QTY: {
      mask: Number,
      radix: '.',
      scale: 0,
      thousandsSeparator: ' ',
      unmask: false,
      padFractionalZeros: false,
      normalizeZeros: false,
      placeholder: '0',
    },
  },
};

const currencyNonZero = {
  mask: `QTY ${currencyText}`,
  lazy: false, // make placeholder always visible
  placeholder: '0.00',

  blocks: {
    QTY: {
      mask: Number,
      radix: '.',
      mapToRadix: [','],
      scale: 2,
      thousandsSeparator: ' ',
      unmask: false,
      padFractionalZeros: true,
      normalizeZeros: false,
      placeholder: '0.00',
    },
  },

  maskValidator: (value: string, isRequired) => {
    if (!isRequired) return undefined;
    return !value ? 'required' : undefined;
  },
};

const currencyWithZero = {
  mask: `QTY ${currencyText}`,
  lazy: false, // make placeholder always visible
  placeholder: '0.00',

  blocks: {
    QTY: {
      mask: Number,
      radix: '.',
      mapToRadix: [','],
      scale: 2,
      thousandsSeparator: ' ',
      unmask: false,
      padFractionalZeros: true,
      normalizeZeros: false,
      placeholder: '0.00',
    },
  },

  maskValidator: (value: string, isRequired) => {
    if (!isRequired) return undefined;
    return isNaN(Number(value)) ? 'required' : undefined;
  },
};

const income = {
  mask: [
    { mask: '' },
    {
      mask: `QTY ${currencyMonthlyText}`,
      lazy: false,
      blocks: {
        QTY: {
          mask: Number,
          radix: '.',
          scale: 0,
          thousandsSeparator: ' ',
        },
      },
    },
  ],
};

const incomeRequired = {
  mask: [
    { mask: '' },
    {
      mask: `QTY ${currencyMonthlyText}`,
      lazy: false,
      blocks: {
        QTY: {
          mask: Number,
          radix: '.',
          scale: 0,
          thousandsSeparator: ' ',
        },
      },
    },
  ],

  maskValidator: (value: number) => {
    return !value ? 'required' : undefined;
  },
};

const currencyRequiredValidZero = {
  mask: [
    { mask: '' },
    {
      mask: `QTY ${currencyMonthlyText}`,
      lazy: false,
      blocks: {
        QTY: {
          mask: Number,
          radix: '.',
          scale: 0,
          thousandsSeparator: ' ',
        },
      },
    },
  ],

  maskValidator: (value: string) => {
    return isNaN(Number(value)) ? 'required' : undefined;
  },
};

const percentMaskOptions = {
  mask: 'QTY %',
  lazy: false, // make placeholder always visible
  placeholder: '0',

  blocks: {
    QTY: {
      mask: Number,
      radix: '.',
      mapToRadix: [','],
      scale: 20,
      thousandsSeparator: ' ',
      unmask: false,
      padFractionalZeros: false,
      normalizeZeros: false,
      placeholder: '0',
    },
  },
};

const passportSeria = {
  mask: '0000',
  lazy: false,
  maskValidator: (value: string) => {
    if (value?.length < 4) return 'tooShort';
    if (['00', '02', '06', '13', '16', '21', '23', '31'].includes(value.slice(0, 2))) return 'passportIncorrectData';

    return undefined;
  },
};

const passportNo = {
  mask: '000 000',
  lazy: false,
  maskValidator: (value: string) => {
    if (value?.length < 6) return 'tooShort';
    if (['000000'].includes(value)) return 'passportIncorrectData';

    return value?.length < 6 ? 'tooShort' : undefined;
  },
};

const passportSeriaNo = {
  mask: '0000 000000',
  lazy: false,
  maskValidator: (value: string) => {
    if (value?.length < 10) return 'tooShort';
    if (['00', '02', '06', '13', '16', '21', '23', '31'].includes(value.slice(0, 2))) return 'passportIncorrectData';
    if (['000000'].includes(value.slice(4, 10))) return 'passportIncorrectData';
    return undefined;
  },
};

const passportSubdivisionCode = {
  mask: '000-000',
  lazy: false,
  maskValidator: (value: string) => {
    return value?.length < 6 ? 'tooShortSubdivisionCode' : undefined;
  },
};

const snils = {
  mask: '000-000-000 00',
  lazy: false,
  maskValidator: (value: string, required = false) => {
    if (!required && !value) return;
    if (value && value.length !== 11) return 'wrong_snils_format';

    const number = value.substring(0, 9);
    const checkSum = value.substring(9);

    const reducer: (sum: number, item: string, index: number) => number = (sum, item, index) => {
      return sum + Number(item) * (9 - index);
    };

    const numberSum = (Array.from(number) as string[]).reduce<number>(reducer, 0);

    if (numberSum < 100 && numberSum !== Number(checkSum)) return 'wrong_snils_format';
    if ((numberSum === 100 || numberSum === 101) && checkSum !== '00') return 'wrong_snils_format';
    if (numberSum > 101 && numberSum % 101 === 100 && checkSum !== '00') return 'wrong_snils_format';
    if (numberSum > 101 && numberSum % 101 !== 100 && numberSum % 101 !== Number(checkSum)) return 'wrong_snils_format';
  },
};

const innValidator = (value: string, required = false) => {
  if (!required && !value) return;
  if ((value && value.length !== 12) || value === '000000000000') return 'wrong_inn_format';

  const number1 = value.substring(0, 11);
  const number2 = value.substring(0, 12);
  const n1 = value.substring(10, 11);
  const n2 = value.substring(11);

  // Алгоритм проверки ИНН 12 знаков.
  //
  // 1. Вычисляется контрольная сумма по 11-ти знакам со следующими весовыми коэффициентами: (7,2,4,10,3,5,9,4,6,8,0)
  // 2. Вычисляется контрольное число(1) как остаток от деления контрольной суммы на 11
  // 3. Если контрольное число(1) больше 9, то контрольное число(1) вычисляется как остаток от деления контрольного числа(1) на 10
  // 4. Вычисляется контрольная сумма по 12-ти знакам со следующими весовыми коэффициентами: (3,7,2,4,10,3,5,9,4,6,8,0).
  // 5. Вычисляется контрольное число(2) как остаток от деления контрольной суммы на 11
  // 6. Если контрольное число(2) больше 9, то контрольное число(2) вычисляется как остаток от деления контрольного числа(2) на 10
  // 7. Контрольное число(1) проверяется с одиннадцатым знаком ИНН и контрольное число(2) проверяется с двенадцатым знаком ИНН.
  //     В случае их равенства ИНН считается правильным.

  const n1coeffs = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];
  const n2coeffs = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0];

  const checkSum = (value: string, coefficients: number[]) => {
    let checkSum =
      (Array.from(value) as string[]).reduce((sum, item, index) => {
        return sum + Number(item) * coefficients[index];
      }, 0) % 11;
    if (checkSum >= 10) checkSum = checkSum % 10;
    return checkSum;
  };

  const checkSum1 = checkSum(number1, n1coeffs);
  const checkSum2 = checkSum(number2, n2coeffs);

  if (checkSum1 !== Number(n1) || checkSum2 !== Number(n2)) return 'wrong_inn_format';
};

const inn = {
  mask: '000000000000',
  lazy: false,
  maskValidator: innValidator,
};

const companyInnValidator = (value: string, required = false) => {
  if (!required && !value) return;
  if ((value && value.length !== 10) || value === '0000000000') return 'wrong_company_inn_format';

  const controlNumber = value.substring(9);

  // Алгоритм проверки ИНН 10 знаков.
  //
  // 1. Вычисляется контрольная сумма со следующими весовыми коэффициентами разрядов кода ИНН: (2,4,10,3,5,9,4,6,8,0).
  // 2. Вычисляется контрольное число как остаток от деления контрольной суммы на 11.
  // 3. Если контрольное число больше 9, то контрольное число вычисляется как остаток от деления контрольного числа на 10.
  // 4. Контрольное число проверяется с десятым знаком ИНН. В случае их равенства ИНН считается правильным.

  const coeffs = [2, 4, 10, 3, 5, 9, 4, 6, 8, 0];

  const checkSum = (value: string, coefficients: number[]) => {
    let checkSum =
      (Array.from(value) as string[]).reduce((sum, item, index) => {
        return sum + Number(item) * coefficients[index];
      }, 0) % 11;
    if (checkSum > 9) checkSum = checkSum % 10;
    return checkSum;
  };

  const _checkSum = checkSum(value, coeffs);

  if (_checkSum !== Number(controlNumber)) return 'wrong_company_inn_format';
};

const companyInn = {
  mask: '0000000000',
  lazy: false,
  maskValidator: companyInnValidator,
};

const checkInn = debounce(
  async (value) => {
    const res = await checkService.checkInn(value);
    return res?.isUnique;
  },
  DEBOUNCE_TIMEOUT,
  { leading: true, trailing: false }
);

const innUnique = {
  mask: '000000000000',
  lazy: false,
  maskValidator: async (value: string, required = false) => {
    const innCheck = innValidator(value, required);
    if (innCheck !== undefined) return innCheck;
    const isUnique = await checkInn(value);
    if (isUnique === undefined) return 'error_check_unique_inn';
    if (!isUnique) return 'inn_is_not_unique';

    return undefined;
  },
};

const iin = {
  mask: '000000000000',
  lazy: false,
  maskValidator: (value: string, required = false) => {
    if (!required && !value) return;
    if (value && value.length !== 12) return 'wrong_iin_format';
  },
};

const drivingLicenseSeria = {
  mask: '0000',
  lazy: false,
};

const drivingLicenseNumber = {
  mask: '000 000',
  lazy: false,
};

export const mobilePhone = {
  mask: '+{7} (000) 000-00-00',
  lazy: false,
  maskValidator: (value: string) => {
    //обязательность проверяется на уровне компонента
    if (value === undefined || value === null || value === '') return undefined;
    return value?.length < 11 ? 'wrongPhone' : undefined;
  },
};

export const mobilePhonePrefixed = {
  mask: '+{7} (000) 000-00-00',
  lazy: false,
  maskValidator: (value: string) => {
    //обязательность проверяется на уровне компонента
    if (value === undefined || value === null || value === '') return undefined;
    return value.slice(0, 2) !== '79' ? 'wrongPhonePrefix' : value.length < 11 ? 'wrongPhone' : undefined;
  },
  isComplete: (value: string) => {
    return value && value.length >= 11;
  },
};

const mobilePhoneGeorgia = {
  mask: '+{995} 000 00 00 00',
  lazy: false,
  maskValidator: (value: string) => {
    return value.length < 12 ? 'wrongPhone' : undefined;
  },
};

const birthPlace = {
  mask: /[а-яёА-ЯЁ\s\d\-+*!?"'/\\№$@%,.:;(){}\[\]=<>#\%\&]+$/,
  maskValidator: (value: string) => {
    return value.length > 150 ? 'tooLong' : undefined;
  },
};

const birthPlaceStrict = {
  mask: /[а-яёА-ЯЁ\s\d\-.()]+$/,
  maskValidator: (value: string) => {
    return value.length > 150 ? 'tooLong' : undefined;
  },
};

const cyrillicStrictWithChars = {
  mask: /[а-яёА-ЯЁ\s\d\-.,:\\№()]+$/,
  maskValidator: (value: string) => {
    if (typeof value !== 'string') return;
    if (value.length < 3) return 'tooShort';
    return value.length > 150 ? 'tooLong' : undefined;
  },
};

const cyrillic = {
  mask: /[а-яёА-ЯЁ\s\-]+$/,
};

const cyrillicAndDigits = {
  mask: /[а-яёА-ЯЁ\s\d]+$/,
};

const cyrillicExtended = {
  mask: /[а-яёА-ЯЁ\s\d\-+*!?"'/\\№$@%,.:;(){}\[\]=<>#\%\&]+$/,
};

const cyrillicStrict = {
  mask: /[а-яёА-ЯЁ\s\d\-.()]+$/,
};

const cyrillicOnlyChar = {
  mask: /^[а-яёА-ЯЁ]+$/,
};

const cyrillicForTweenName = {
  mask: /^[а-яёА-ЯЁ]+(\-|\s)?[а-яёА-ЯЁ]*$/,
};

const digits = {
  mask: /^\d+$/,
};

const children = {
  mask: '0[0]',
  lazy: true,
  maskValidator: (value: string, isRequired: boolean = undefined) => {
    if (!isRequired) return undefined;
    return isNaN(Number(value)) ? 'required' : undefined;
  },
};

const patternOptions = (pattern) => {
  return {
    mask: pattern,
  };
};

export type InputMaskType = {
  mask?: string;
  placeholder?: string;
  lazy?: boolean;
  blocks?: Record<string, unknown>;
  maskValidator?: (value: string, required?: boolean) => string | undefined;
  isComplete?: (value: string) => boolean;
};

export const getInputMask: (maskType: Masks, pattern?: string) => InputMaskType = (maskType, pattern = '') => {
  switch (maskType) {
    case 'int':
      return intMaskOptions;
    case 'intWithZero':
      return intWithZero;
    case 'intNonZero':
      return intRequired;
    case 'intRequiredValidZero':
      return intRequiredValidZero;
    case 'float':
      return floatMaskOptions;
    case 'currency':
      return currencyMaskOptions;
    case 'currencyNonZero':
      return currencyNonZero;
    case 'currencyWithZero':
      return currencyWithZero;
    case 'currencyInt':
      return currencyIntMaskOptions;
    case 'currencyRequiredValidZero':
      return currencyRequiredValidZero;
    case 'percent':
      return percentMaskOptions;
    case 'unlimited':
      return floatUnlimitedMaskOptions;
    case 'digits':
      return digits;
    case 'email':
      return emailOptions;
    case 'emailUnique':
      return emailUnique;
    case 'passportSeria':
      return passportSeria;
    case 'passportSubdivisionCode':
      return passportSubdivisionCode;
    case 'passportNo':
      return passportNo;
    case 'passportSeriaNo':
      return passportSeriaNo;
    case 'cyrillic':
      return cyrillic;
    case 'cyrillicAndDigits':
      return cyrillicAndDigits;
    case 'cyrillicExtended':
      return cyrillicExtended;
    case 'cyrillicStrict':
      return cyrillicStrict;
    case 'cyrillicStrictWithChars':
      return cyrillicStrictWithChars;
    case 'cyrillicOnlyChar':
      return cyrillicOnlyChar;
    case 'cyrillicForTweenName':
      return cyrillicForTweenName;
    case 'snils':
      return snils;
    case 'inn':
      return inn;
    case 'companyInn':
      return companyInn;
    case 'innUnique':
      return innUnique;
    case 'iin':
      return iin;
    case 'mobilePhone':
      return mobilePhone;
    case 'mobilePhoneGeorgia':
      return mobilePhoneGeorgia;
    case 'mobilePhonePrefixed':
      return mobilePhonePrefixed;
    case 'birthPlace':
      return birthPlace;
    case 'birthPlaceStrict':
      return birthPlaceStrict;
    case 'drivingLicenseSeria':
      return drivingLicenseSeria;
    case 'drivingLicenseNumber':
      return drivingLicenseNumber;
    case 'children':
      return children;
    case 'income':
      return income;
    case 'incomeRequired':
      return incomeRequired;
    case 'intWithPlaceholder':
      return intWithPlaceholder;
    case 'pattern':
      return patternOptions(pattern);
    default:
      return {};
  }
};

export const isNumerical = (mask: Masks): boolean => {
  switch (mask) {
    case 'int':
    case 'intWithZero':
    case 'intNonZero':
    case 'intRequired':
    case 'float':
    case 'currency':
    case 'currencyInt':
    case 'currencyNonZero':
    case 'percent':
    case 'unlimited':
      return true;
  }
  return false;
};

export type Masks =
  | 'int'
  | 'intWithZero'
  | 'intNonZero'
  | 'intRequired'
  | 'intRequiredValidZero'
  | 'float'
  | 'currency'
  | 'currencyInt'
  | 'currencyNonZero'
  | 'currencyWithZero'
  | 'currencyRequiredValidZero'
  | 'percent'
  | 'unlimited'
  | 'digits'
  | 'email'
  | 'emailUnique'
  | 'passportSeria'
  | 'passportNo'
  | 'passportSeriaNo'
  | 'passportSubdivisionCode'
  | 'pattern'
  | 'cyrillic'
  | 'cyrillicAndDigits'
  | 'cyrillicExtended'
  | 'cyrillicStrict'
  | 'cyrillicStrictWithChars'
  | 'cyrillicOnlyChar'
  | 'cyrillicForTweenName'
  | 'snils'
  | 'inn'
  | 'innUnique'
  | 'companyInn'
  | 'iin'
  | 'mobilePhone'
  | 'mobilePhonePrefixed'
  | 'mobilePhoneGeorgia'
  | 'birthPlace'
  | 'birthPlaceStrict'
  | 'drivingLicenseSeria'
  | 'drivingLicenseNumber'
  | 'children'
  | 'income'
  | 'incomeRequired'
  | 'intWithPlaceholder';

export const BS_SMS_CODE_PATTERN = '0000';
export const BS_SMS_CODE_PATTERN_MANDARIN = '000000';
