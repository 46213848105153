// eslint-disable-next-line no-unused-vars
import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';
import { creditService } from '@brainysoft/lk-components';

type ProlongationSetting = { days: number; amount: number; date?: string; dateRange?: { start: number; end: number } };
export const useProlongationSettings: (contractId: string | number) => UseQueryResult<ProlongationSetting[]> = (
  contractId
) => {
  return useQuery(['ProlongationSettings', contractId], () => {
    if (contractId) {
      return creditService.prolongationSettings(contractId) as Promise<ProlongationSetting[]>;
    } else {
      return new Promise((resolve: (arg: any) => any) => {
        resolve([]);
      }) as Promise<ProlongationSetting[]>;
    }
  });
};
