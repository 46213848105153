import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface IIcon {
  color?: string;
  className?: string;
}

export const ExternalLinkIcon: React.FC<IIcon> = (props) => {
  const classes = useThemeStyles('Icons');

  const { color, className } = props;

  return (
    <span className={`${className ?? ''} ${classes.externalLinkIcon} ${color ?? ''}`}>
      <svg width='8px' height='8px' viewBox='16 8 8 8'>
        <polyline
          fill='none'
          stroke='#000000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          points='23.5,13.866 23.5,15.5 16.5,15.5 16.5,8.5 18.133,8.5 '
        />
        <polyline
          fill='none'
          stroke='#000000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          points='19.883,8.5 23.5,8.5 23.5,12.116 '
        />
        <line
          fill='none'
          stroke='#000000'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          x1='19.592'
          y1='12.408'
          x2='23.267'
          y2='8.733'
        />
      </svg>
    </span>
  );
};
