import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { priceFormatedObject } from '../../helpers/priceFormattedObject';
import CurrencyIcon from '../CurrencyIcon';
import * as config from '@brainysoft/lk-custom/config';

interface CurrencyProps {
  amount?: number;
  currency?: string;
  showDecimals?: boolean;
  showIcon?: boolean;
  ratio?: number;
  size?: string;
  className?: string;
}

export const Currency: React.FC<CurrencyProps> = (props) => {
  const classes = useThemeStyles('Price');

  const {
    amount,
    currency = (config as any)?.CURRENCY,
    showDecimals = true,
    showIcon = true,
    ratio = 0.6,
    size,
    className,
  } = props;

  const price = priceFormatedObject(amount ? amount : 0);

  const style = size ? { fontSize: size } : undefined;

  return (
    <span className={`${classes.price} ${className ?? ''}`} style={style}>
      {price.integer}
      {showDecimals && (
        <span className={classes.priceFractional} style={{ fontSize: `${ratio}em` }}>
          {price.fractionalWithSeparator}
        </span>
      )}
      {showIcon && (
        <span className={classes.currencyIcon}>
          <CurrencyIcon currencyISO={currency} />
        </span>
      )}
    </span>
  );
};
