import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
// eslint-disable-next-line no-unused-vars
import { AvatarProps } from 'antd/es/avatar';
import { Avatar as AntdAvatar } from 'antd';

interface IAvatar extends AvatarProps {
  className?: string;
}

export const Avatar: React.FC<IAvatar> = (props) => {
  const classes = useThemeStyles('Avatar');

  const externalClass = props.className ?? '';

  return (
    <AntdAvatar {...props} className={`${classes.avatar} ${externalClass}`}>
      {props.children}
    </AntdAvatar>
  );
};
