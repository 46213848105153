import { tAsArrayHelper, tHelper } from '../utils/trans';
import * as customerCfg from '@brainysoft/lk-custom/config';

type customerDateParserFunc = (ts: number, opts?: Record<string, any>) => string | undefined;
export function dateParser(timestamp, yearText = false) {
  const customDateParser: customerDateParserFunc =
    typeof customerCfg['DATE_PARSER'] === 'function' ? customerCfg['DATE_PARSER'] : undefined;
  if (customDateParser !== undefined) return customDateParser(timestamp, { yearText });

  let dateString = '';
  const date = new Date(timestamp);
  dateString = date.getDate() + ' ' + getMonth(date) + ' ' + date.getFullYear();
  if (yearText) {
    dateString += tHelper('general:dateSpecs.yearText');
  }
  return dateString;
}

function getDay(date) {
  const day = date.getDate();
  if (day < 10) {
    return `0${day}`;
  } else {
    return day;
  }
}

function getMonth(date) {
  const months = tAsArrayHelper('general:dateSpecs.months');
  return months[date.getMonth()];
}

const getMonthNumber = (date) => {
  const month = date.getMonth() + 1;
  if (month < 10) {
    return `0${month}`;
  } else {
    return month;
  }
};

export const shortDateParser = (timestamp: string) => {
  const date = new Date(timestamp);
  return getDay(date) + '.' + getMonthNumber(date) + '.' + date.getFullYear();
};
