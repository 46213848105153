import React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { FormItem, IColumnProps } from '../FormItem';
import { Field } from 'formik';
import { SelectProps } from 'antd/lib/select';
import { NoData } from '../NoData';
import { Select as AntdSelect, ConfigProvider } from 'antd';

interface ISelectProps extends SelectProps<any>, IColumnProps {
  className?: string;
  name: string;
  size?: 'small' | 'large';
  label?: string;
  options?: Array<any>;
  required?: boolean;
  onChange?: any;
  onBlur?: any;
  hidden?: boolean;
  dictionary?: any;
  disabled?: boolean;
  validNullValue?: boolean;
  filterOption?: any;
  showSearch?: boolean;
}

const Option = AntdSelect.Option;

export const _Select = (props: ISelectProps) => {
  const classes = useThemeStyles('Form/Select');

  const [inputValue, setInputValue] = React.useState<any>(undefined);

  const size = props.size ? props.size : 'large';

  const validateRequired = (value) => {
    const hasValue = value || (props.validNullValue && value !== undefined);
    return !hasValue ? 'required' : undefined;
  };

  const options = props.options ? props.options : [];
  // maritalStatus.unshift({ id: null, name: t('noValue') });

  return (
    <Field name={props.name} validate={props.required ? validateRequired : null}>
      {({ form, field, meta }) => {
        //setting an initial value
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useEffect(() => {
          field.value !== undefined
            ? setInputValue(field.value)
            : setInputValue(meta.initialValue ? meta.initialValue : undefined);
        }, [field.value]);

        return (
          <FormItem {...props} meta={meta}>
            <ConfigProvider renderEmpty={() => <NoData />}>
              <AntdSelect
                id={field.name}
                className={classes.select}
                size={size}
                value={inputValue}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                showArrow={true}
                disabled={props.disabled}
                allowClear={props.allowClear}
                onChange={(value) => {
                  setInputValue(value);
                  form.setFieldValue(field.name, value);
                  if (typeof props.onChange === 'function') props.onChange(value);
                }}
                onBlur={() => {
                  form.setFieldValue(field.name, inputValue);
                  form.setFieldTouched(field.name, true);
                  if (typeof props.onBlur === 'function') props.onBlur(inputValue);
                }}
                showSearch={props.showSearch}
                filterOption={props.filterOption}
              >
                {options.map((option, index) => {
                  return (
                    <Option key={index} value={option.value}>
                      {option.option}
                    </Option>
                  );
                })}
              </AntdSelect>
            </ConfigProvider>
          </FormItem>
        );
      }}
    </Field>
  );
};

export const Select = React.memo(_Select);
