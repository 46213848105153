import React from 'react';
import { SurveyAnswerT, SurveyT } from '@brainysoft/lk-components';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Input } from '../../packages/Formik/Components/Input';
import { Select } from '../../packages/Formik/Components/Select';

export interface SurveyFormProps {
  survey: SurveyT;
  slot: string;
  type?: 'wide' | 'narrow';
}

export const SurveyForm: React.FC<SurveyFormProps> = (props) => {
  const classes = useThemeStyles('Survey');

  const { survey, slot, type = 'wide' } = props;

  const defaultAnswers: SurveyAnswerT[] = [
    {
      key: 'no',
      order: 1,
      value: 'Нет',
    },
    {
      key: 'yes',
      order: 0,
      value: 'Да',
    },
  ];

  const answers = survey.answers || defaultAnswers;
  const answerOptions = answers
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((answer) => {
      return {
        option: answer.value,
        value: answer.key,
      };
    });

  return (
    <div className={classes.surveyWrapper}>
      <h3>{survey.question}</h3>
      <Input name={`surveys.${survey.name}.id`} initialValue={survey.id} hidden />
      <Input name={`surveys.${survey.name}.slot`} initialValue={slot} hidden />
      <Select name={`surveys.${survey.name}.answer`} options={answerOptions} />
    </div>
  );
};
