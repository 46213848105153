import { INTEGRATIONS } from '../config';

class GoogleAnalyticsDriver {
  constructor() {
    this.goals = (INTEGRATIONS as Record<string, any>)?.googleAnalytics?.goals
      ? (INTEGRATIONS as Record<string, any>).googleAnalytics.goals
      : {};
  }

  goals = {};

  send(event: string) {
    if (typeof (window as any).gtag !== 'function') return;
    if (!this.goals[event]) return;
    (window as any).gtag('event', this.goals[event].event, { event_category: this.goals[event].event_category });
  }
}

export const useGoogleAnalytics = () => {
  return new GoogleAnalyticsDriver();
};
