import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

export interface ILogo {
  inverted?: boolean;
  type?: 'general' | 'auth' | 'footer' | 'registration';
  onClick?: (event: React.SyntheticEvent) => void;
}

const Logo: React.FC<ILogo> = (props) => {
  const classes = useThemeStyles('Logo');

  let logoClass = props.inverted ? 'inverted' : '';
  switch (props.type) {
    case 'auth':
      logoClass = 'auth';
      break;
    case 'registration':
      logoClass = 'registration';
      break;
    case 'footer':
      logoClass = 'footer';
      break;
  }

  const onClick = props.onClick && typeof props.onClick === 'function' ? props.onClick : undefined;

  return (
    <div className={classes.logoWrapper} onClick={onClick}>
      <div className={`${classes.logoImg} ${logoClass}`} />
      {/*<img src={logoSrc} className={classes.logoImg} alt="" />*/}
    </div>
  );
};

export default Logo;
