import React from 'react';
import { useQuery } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { UseQueryResult } from 'react-query/types/react/types';
import { processService, ProcessType } from '@brainysoft/lk-components';

type LockT = { isLocked: boolean };

export const useLock: (processName: ProcessType) => UseQueryResult<LockT> = (processName) => {
  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(false);

  return useQuery(
    ['lock', processName],
    () => {
      return processService.isLocked(processName) as Promise<LockT>;
    },
    {
      refetchInterval: refetchInterval,
      onSettled: (data) => {
        if (data?.isLocked) {
          setRefetchInterval(20000);
        } else {
          setRefetchInterval(false);
        }
      },
    }
  );
};
