import i18n from 'i18next';

export const trans: (code: string, options?: Record<string, unknown>) => string = (code, options = {}) => {
  const [namespace, key] = code.includes(':') ? code.split(':') : ['general', code];
  return i18n.exists(`custom__${namespace}:${key}`)
    ? i18n.t(`custom__${namespace}:${key}`, options)
    : i18n.t(`${namespace}:${key}`, options);
};

export const keyExists: (code: string) => boolean = (code) => {
  const [namespace, key] = code.includes(':') ? code.split(':') : ['general', code];
  return i18n.exists(`custom__${namespace}:${key}`) || i18n.exists(`${namespace}:${key}`);
};

export const tAsArray: (code: string, options?: Record<string, unknown>) => string[] = (code, options) => {
  const textContent = keyExists(code) ? trans(code, { ...(options ?? {}), returnObjects: true }) : code;
  return (Array.isArray(textContent) ? textContent : [textContent]) as string[];
};

export const useTranslation = () => {
  return { t: trans, i18n: i18n, keyExists: keyExists, tAsArray: tAsArray };
};
