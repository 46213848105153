import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { PrintFormContextType, printFormService as printForm, ProcessType } from '@brainysoft/lk-components';
// eslint-disable-next-line no-unused-vars
import { PrintFormType } from '@brainysoft/lk-components';
import { useTranslation } from '../../utils/useTranslation';
import { Icon, IconType } from '../Icon';
import { LoadingSpinner } from '../LoadingSpinner';

interface IPFDocumentProps {
  contextType?: PrintFormContextType;
  printFormType: PrintFormType;
  params: any;
  name: string;
  icon?: IconType;
  type: 'link' | 'block';
  onClick?: (e: React.SyntheticEvent) => void;
  onDocumentLoadSuccess?: (link: string) => void;
  onDocumentLoadError?: () => void;
  className?: string;
  hideLoading?: boolean;
}

export const PrintFormDocument: React.FC<IPFDocumentProps> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('PrintFormDocument');

  const onDocumentLoadSuccess = props.onDocumentLoadSuccess ? props.onDocumentLoadSuccess : () => {};
  const onDocumentLoadError = props.onDocumentLoadError ? props.onDocumentLoadError : () => {};
  const { hideLoading = false } = props;

  const onClick = (e) => {
    e.preventDefault();
    if (props.onClick) props.onClick(e);
    if (downloadLink !== '#') window.open(downloadLink, '_blank');
  };

  const icon = props.icon ? props.icon : '';

  const [isPFLoading, setIsPFLoading] = React.useState(true);
  const [isPFDisabled, setIsPFDisabled] = React.useState(true);
  const [downloadLink, setDownloadLink] = React.useState('#');

  const mobilePhone = props.params?.variables?.mobilePhone;

  React.useEffect(() => {
    let cancel = false;
    const fetchDocumentLink = async () => {
      setDownloadLink('#');
      setIsPFLoading(true);
      setIsPFDisabled(true);
      const unregistered =
        props.contextType === PrintFormContextType.PROCESS && props.params?.processType === ProcessType.REGISTRATION;
      const res = unregistered
        ? await printForm.getDownloadLinkUnregistered(props.contextType, props.printFormType, props.params ?? [])
        : await printForm.getDownloadLink(props.contextType, props.printFormType, props.params ?? []);
      const link = res?.link?.pdf?.download;

      if (!cancel) {
        if (link) {
          setDownloadLink(link);
          setIsPFDisabled(false);
          onDocumentLoadSuccess(link);
        } else {
          onDocumentLoadError();
        }
        setIsPFLoading(false);
      }
    };
    fetchDocumentLink();
    return () => {
      cancel = true;
    };
  }, [mobilePhone]);

  return (
    <div
      className={`${props.type == 'block' ? classes.documentsBlockWrapper : classes.documentsLinkWrapper} ${
        props.className ?? ''
      }`}
    >
      <div
        onClick={onClick}
        className={[
          (isPFLoading || isPFDisabled) && !hideLoading ? ' disabled' : '',
          props.type == 'block' ? classes.documentBlock : classes.documentLink,
        ].join(' ')}
      >
        <span className={classes.documentContentWrapper}>
          {isPFLoading && !hideLoading && (
            <span className={classes.documentIcon}>
              <LoadingSpinner size={6} margin={2} spotNumber={2} delayRatio={2} className={'grey'} />
            </span>
          )}
          {icon && (!isPFLoading || hideLoading) && (
            <span className={classes.documentIcon}>
              <Icon type={icon} />
            </span>
          )}
          {props.type == 'block' && (
            <div className={classes.documentContent}>
              {isPFLoading && <span>{t('printForms:creating', { document: props.name.toLowerCase() })}</span>}
              {!isPFLoading && <span>{props.name}</span>}
            </div>
          )}
          {props.type !== 'block' && <span>{props.name}</span>}
        </span>
        {props.type == 'block' && <Icon type='download' />}
      </div>
    </div>
  );
};
