import * as defaultComponents from '../default';
import { Footer } from './Footer';
import { ContractDetails } from './ContractDetails';
import { LoanAppCreateWidget } from './LoanAppCreateWidget';

export default {
  ...defaultComponents,
  Footer,
  ContractDetails,
  LoanAppCreateWidget,
};
