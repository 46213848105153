import { FC } from 'react';
import { FooterT } from '@brainysoft/lk-components';
import { FooterView } from '../../../components/Footer/FooterView';

const footer: FooterT = {
  menu: [
    [
      {
        text: 'Карьера',
        url: 'https://asiacredit.ru/about/karera/',
        target: '_blank',
      },
      {
        text: 'Документы',
        url: 'https://asiacredit.ru/about/dokumenty/',
        target: '_blank',
      },
      {
        text: 'Контакты',
        url: 'https://asiacredit.ru/ofisy/spisok/',
        target: '_blank',
      },
    ],
    [
      {
        text: 'Займы гражданам России',
        url: 'https://asiacredit.ru/zaymy-grazhdanam-rossii/',
        target: '_blank',
      },
      {
        text: 'Займы гражданам Узбекистана',
        url: 'https://asiacredit.ru/zaymy-grazhdanam-uzbekistana/',
        target: '_blank',
      },
      {
        text: 'Займы гражданам Таджикистана',
        url: 'https://asiacredit.ru/zaymy-grazhdanam-tadzhikistana/',
        target: '_blank',
      },
      {
        text: 'Займы гражданам Кыргызстана',
        url: 'https://asiacredit.ru/zaymy-grazhdanam-kyrgyzstana/',
        target: '_blank',
      },
    ],
    [
      {
        text: 'Патент в кредит',
        url: 'https://asiacredit.ru/patent-v-kredit.php',
        target: '_blank',
      },
      {
        text: 'Бланки',
        url: 'https://asiacredit.ru/faq/7/',
        target: '_blank',
      },
      {
        text: 'Образцы документов',
        url: 'https://asiacredit.ru/faq/6/',
        target: '_blank',
      },
    ],
  ],
  disclaimer: [
    'Информация о структуре и составе участников ООО МФК «МЦК», в том числе о лицах, под контролем либо значительным влиянием которых находится ООО МФК «МЦК», соответствует информации, направленной «15» марта 2021г. в Банк России для размещения на официальном сайте Банка России.',
    'Услуги предоставляются ООО МФК «МЦК». Свидетельство о внесении сведений о юридическом лице в государственный реестр микрофинансовых организаций за № 651303045003029 от 11.04.2013 г.',
    {
      text: 'Список участников ООО МФК "МЦК", под контролем, либо значительным влиянием которых находится микрофинансовая компания',
      url: '#',
      target: '_blank',
    },
  ],
  copyright: '© "АзияКредит" ',
};

export const Footer: FC = () => {
  return <FooterView footer={footer} />;
};
