import React from 'react';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { Col, Form } from 'antd';
import { FieldMetaProps } from 'formik';
import { FormItemProps } from 'antd/lib/form';
import { useTranslation } from '../../../../utils/useTranslation';
import { ValidateStatus } from 'antd/lib/form/FormItem';

export interface IColumnProps {
  columnProps?: any;
  wrapInColumn?: boolean;
}

interface IFormItemProps extends FormItemProps, IColumnProps {
  name?: string;
  label?: string;
  required?: boolean;
  meta: FieldMetaProps<any>;
  className?: string;
  hidden?: boolean;
  isPanelOpen?: boolean;
  showIcon?: boolean;
  enterButton?: React.FC | React.Component;
  validateStatus?: ValidateStatus;
  onReset?: any;
}

const AntdFormItem = Form.Item;

// eslint-disable-next-line react/display-name
export const FormItem: React.FC<IFormItemProps> = React.memo(
  (props) => {
    const classes = useThemeStyles('Form/FormItem');

    const { t } = useTranslation();

    const enterButtonClassName = props.enterButton ? `${classes.enterButton}` : null;
    const labeledClassName = props.label ? `${classes.labeled}` : null;
    const errorClassName = props.meta.touched && props.meta.error && !props.isPanelOpen ? 'error' : '';
    const errorMessage =
      props.meta.touched && props.meta.error && !props.isPanelOpen ? t(`errors:${props.meta.error}`) : null;
    const showIcon = props.showIcon == undefined ? true : props.showIcon;
    const hasFeedback = (props.meta.touched || props.validateStatus) && showIcon;
    const validateStatus = props.validateStatus ? props.validateStatus : errorClassName;
    const wrapInColumn = props.wrapInColumn;
    const columnProps = props.columnProps;
    const hidden = props.hidden;

    // if(props.meta.touched && props.meta.error) console.log(`Validation failed at field ${props.name}`);

    // if (props.name == 'meanIncome')
    //     console.log(props.meta.value, props.meta.touched, props.meta.error, props.validateStatus, validateStatus, !!hasFeedback, errorMessage);

    return (
      <React.Fragment>
        {!!wrapInColumn && (
          <Col {...columnProps} style={hidden ? { display: 'none' } : undefined}>
            <div className='field'>
              <div
                className={`field-wrapper ${errorClassName} ${classes.formItem} ${labeledClassName} ${enterButtonClassName}`}
                style={props.hidden ? { display: 'none' } : undefined}
              >
                <AntdFormItem
                  hasFeedback={!!hasFeedback}
                  help={errorMessage}
                  validateStatus={validateStatus}
                  style={{ width: '100%' }}
                  label={props.label ? <FieldLabel text={props.label} required={props.required} /> : null}
                  colon={false}
                >
                  {props.children}
                </AntdFormItem>
                {props.enterButton}
              </div>
            </div>
          </Col>
        )}
        {!wrapInColumn && (
          <div
            className={`field-wrapper ${errorClassName} ${classes.formItem} ${labeledClassName} ${enterButtonClassName}`}
            style={props.hidden ? { display: 'none' } : undefined}
          >
            <AntdFormItem
              hasFeedback={!!hasFeedback}
              help={errorMessage}
              validateStatus={validateStatus}
              style={{ width: '100%' }}
              label={props.label ? <FieldLabel text={props.label} required={props.required} /> : null}
              colon={false}
            >
              {props.children}
            </AntdFormItem>
            {props.enterButton}
          </div>
        )}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.meta.value !== nextProps.meta.value && prevProps.meta.error !== nextProps.meta.error;
  }
);

interface IFieldLabel {
  text?: string;
  required?: boolean;
}

export const FieldLabel: React.FC<IFieldLabel> = (props) => {
  return (
    <React.Fragment>
      {props.text}
      {props.required && <span style={{ position: 'relative', marginLeft: '4px', color: 'red' }}>*</span>}
    </React.Fragment>
  );
};
