// eslint-disable-next-line no-unused-vars
import { SettingsType } from '@brainysoft/lk-components';
import { useSettings } from '../queries/useSettings';
// eslint-disable-next-line no-unused-vars
import { InterfaceModules } from '@brainysoft/lk-components';

export type InterfaceModuleSettingT = ({ active: boolean } & Record<string, unknown>) | null;

export const useInterfaceModuleSetting: (interfaceModule: InterfaceModules) => InterfaceModuleSettingT = (
  interfaceModule
) => {
  const { data } = useSettings();

  const interfaceModulesSettings = data ? data[SettingsType.INTERFACE_MODULES] : {};

  return interfaceModulesSettings[interfaceModule] ?? null;
};
