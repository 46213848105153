import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';

interface ILoadingSpinner {
  spotNumber?: number;
  size?: number;
  margin?: number;
  animationDuration?: number;
  delayRatio?: number;
  className?: string;
}

export const LoadingSpinner: React.FC<ILoadingSpinner> = (props) => {
  const classes = useThemeStyles('LoadingSpinner');

  const { spotNumber = 5, animationDuration = 1.2, delayRatio = 3, size = 12, margin = 6, className = '' } = props;

  return (
    <div className={`${classes.spinnerContainer} ${className}`}>
      <div
        className={classes.spinnerWrapper}
        style={{
          width: `calc(${spotNumber} * ${size}px + ${spotNumber - 1} * ${margin}px)`,
        }}
      >
        {Array(spotNumber)
          .fill(null)
          .map((_item, index) => {
            const style = {
              width: size,
              height: size,
              animationDuration: `${animationDuration}s`,
              animationDelay: `${(index * animationDuration) / spotNumber / delayRatio}s`,
            };
            return <div className={classes.spot} style={style} key={index} />;
          })}
      </div>
    </div>
  );
};
