import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { CreditProductT, ProcessType } from '@brainysoft/lk-components';
import { ProcessStorageService } from '../processes/ProcessStorage';

export const useInitLoanAppProcess = () => {
  const router = useRouter();

  const initProcess = useCallback(
    //не преобразовываем транши в дни:
    //это преобразование произойдет при инициализации процесса создания заявки
    async (values, creditProduct: CreditProductT) => {
      await ProcessStorageService.setData(ProcessType.LOAN_APP, { ...values, isProfileChangeRequired: true });
      await router.push(`/loan-app/create`);
    },
    [router]
  );

  return initProcess;
};
