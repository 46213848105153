import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import Logo from '../Logo';
import { Col, Row } from 'antd';
import { DisplayModeType, FooterT } from '@brainysoft/lk-components';
import { AppContext } from '@brainysoft/lk-components';

interface FooterViewProps {
  footer?: FooterT;
}

export const FooterView: React.FC<FooterViewProps> = (props) => {
  const classes = useThemeStyles('Footer');

  const { footer } = props;

  const { state } = AppContext.useContainer();
  const mode = state.displayMode;

  const menu = footer?.menu ?? [];
  const disclaimer = footer?.disclaimer ?? [];
  const copyright = footer?.copyright ?? [];
  const contacts = footer?.contacts ?? [];
  const menuColumnNumber = menu.length ? 24 / menu.length : 24;
  const contactsColumnNumber = contacts.length ? 24 / contacts.length : 24;

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footerContainer}>
        <div className={classes.leftSide}>
          <Logo type='footer' />
          {mode !== DisplayModeType.MOBILE && (
            <div className={classes.copyright}>
              <div>{copyright}</div>
              <div className={classes.brainysoft}>
                Powered by{' '}
                <a href={'https://www.brainysoft.ru'} target={'_blank'} rel='noopener noreferrer'>
                  Brainysoft
                </a>
              </div>
            </div>
          )}
        </div>
        <div className={classes.rightSide}>
          <Row>
            {menu.map((column, index) => {
              const list = Array.isArray(column) ? column : [];
              return (
                <Col xs={24} md={menuColumnNumber} key={index}>
                  <ul className={classes.footerMenu}>
                    {list.map((item, index) => {
                      return (
                        <li className={classes.footerMenuItem} key={index}>
                          <a href={item.url} target={item.target}>
                            {item.text}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>
          <Row>
            {contacts.map((column, index) => {
              const list = Array.isArray(column) ? column : [];
              return (
                <Col xs={24} md={contactsColumnNumber} key={index}>
                  <ul className={classes.footerMenu}>
                    {list.map((item, index) => {
                      const text = Array.isArray(item.text) ? item.text : [item.text];
                      return (
                        <li className={classes.footerMenuItem} key={index}>
                          <div className={classes.footerContactLabel}>{item.label}</div>
                          <div className={classes.footerContactContent}>
                            {!!item?.url && (
                              <a href={item.url} target={item.target}>
                                {text.map((txt, index) => {
                                  return <div key={index}>{txt}</div>;
                                })}
                              </a>
                            )}
                            {!item?.url &&
                              text.map((txt, index) => {
                                return <div key={index}>{txt}</div>;
                              })}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              );
            })}
          </Row>
          <div className={classes.footerDisclaimer}>
            {disclaimer.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {typeof item === 'string' && (
                    <div className={classes.footerDisclaimerItem} key={index}>
                      {item}
                    </div>
                  )}
                  {typeof item === 'object' && (
                    <div className={classes.footerDisclaimerItem} key={index}>
                      <a href={item.url} target={item.target}>
                        {item.text}
                      </a>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          {mode === DisplayModeType.MOBILE && (
            <div className={classes.copyright}>
              <div>{copyright}</div>
              <div className={classes.brainysoft}>
                Powered by{' '}
                <a href={'https://www.brainysoft.ru'} target={'_blank'} rel='noopener noreferrer'>
                  Brainysoft
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
