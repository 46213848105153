import { cacheService as Storage } from '@brainysoft/lk-components';
import { TENANT_DOMAIN } from '@brainysoft/lk-custom/config';

export abstract class AProcessStorage<T> {
  protected prefix: string;

  public async getData(processType: T, params: string[]) {
    const _values: any = {};
    for (const param of params) {
      _values[param] = await this.getItem(processType, param);
    }
    return _values;
  }

  public async getItem(processType: T, item: string) {
    const itemValue = await Storage.getItem(this.paramKey(processType, item));
    const ttl = await Storage.getItem(this.ttlKey(processType, item));

    if (ttl && Date.now() > ttl) {
      await this.deleteData(processType, [item]);
      return;
    }
    return itemValue;
  }

  public async setItem(processType: T, key: string, value: unknown, ttl?: number) {
    await Storage.setItem(this.paramKey(processType, key), value);
    if (ttl) await Storage.setItem(this.ttlKey(processType, key), Date.now() + ttl);
  }

  public async setData(processType: T, params: Record<string, unknown>, ttl?: number) {
    for (const param in params) {
      await this.setItem(processType, param, params[param], ttl);
    }
  }

  public async deleteItem(processType: T, item: string) {
    await Storage.removeItem(this.paramKey(processType, item));
    await Storage.removeItem(this.ttlKey(processType, item));
  }

  public async deleteData(processType: T, params: string[]) {
    for (const param of params) {
      await this.deleteItem(processType, param);
    }
  }

  public async resetAll(processType: T) {
    const keys = await Storage.getAllKeys();
    const fullPrefix = `${TENANT_DOMAIN}:${this.prefix}:${processType}`;
    const processKeys = (Array.isArray(keys) ? keys : []).filter((key) => {
      return key.startsWith(fullPrefix) && !key.endsWith('ttl');
    });
    for (const key of processKeys) {
      await this.deleteItem(processType, key.replaceAll(fullPrefix + ':', ''));
    }
  }

  private paramKey(processType: T, paramName: string) {
    return `${this.prefix}:${processType}:${paramName}`;
  }

  private ttlKey(processType: T, paramName: string) {
    return `${this.prefix}:${processType}:${paramName}:ttl`;
  }
}
