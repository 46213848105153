import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { Popover } from 'antd';
import { tokenService } from '@brainysoft/lk-components';
import { authService, cacheService as Storage } from '@brainysoft/lk-components';
import { DisplayModeType, ClientT, AvatarT } from '@brainysoft/lk-components';
import { Button } from '../Button';
import { HEADER_INVERTED } from '../../config';
import { ProfileIcon } from '../../icons/profile';
import Link from 'next/link';
// eslint-disable-next-line no-unused-vars
import { Avatar } from '../Avatar';
import { MainMenuItem } from '../MainMenuItem';
import { useTranslation } from '../../utils/useTranslation';

interface IProps {
  client?: ClientT;
  avatar?: AvatarT;
  mode: DisplayModeType;
}

export const ProfileMenu: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('ProfileMenu');
  const { t } = useTranslation();

  const { client, avatar } = props;

  const handleLogout = () => {
    tokenService.logout();
    authService.signOut();
    Storage.clear();
    window.location.href = '/auth';
  };

  const content = (
    <React.Fragment>
      {client && (
        <div className={classes.popoverWrapper}>
          <div className={classes.profileWrapper}>
            <div className={classes.avatarWrapper}>
              {avatar?.avatarBase64 ? (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64 }}
                  className={classes.avatarWrapper}
                  src={avatar.avatarBase64}
                />
              ) : (
                <img src='/img/profile.svg' alt='' />
              )}
            </div>
            <div className={classes.fioWrapper}>
              <span className={classes.lastName}>{client.lastName}</span>
              <span>
                {client.firstName} {client.patronymic}
              </span>
            </div>
          </div>
          <div className={classes.profileWrapper}>
            <Link href='/profile'>
              <a>{t('profile:menu.profile')}</a>
            </Link>
          </div>
          <Button onClick={handleLogout}>{t('profile:menu.logout')}</Button>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {props.mode === DisplayModeType.DESKTOP && (
        <Popover arrowPointAtCenter={true} title={''} placement='bottomRight' trigger='click' content={content}>
          <div className={classes.profile}>
            <ProfileIcon inverted={HEADER_INVERTED} />
          </div>
        </Popover>
      )}
      {props.mode !== DisplayModeType.DESKTOP && (
        <React.Fragment>
          <MainMenuItem itemText={t('profile:menu.profile')} offcanvas={true} href={'/profile'} />
          <MainMenuItem itemText={t('profile:menu.logout')} offcanvas={true} onClick={handleLogout} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
