import { config, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign, faEuroSign, faRubleSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { t, exists } from 'i18next';

interface IProps {
  currencyISO?: string;
}

config.autoAddCss = false;

export type CurrencyType = {
  code: 'USD' | 'EUR' | 'RUR' | 'GEL' | 'KZT';
  text: string;
  icon?: IconDefinition;
};

export const CURRENCIES: CurrencyType[] = [
  {
    code: 'USD',
    icon: faDollarSign,
    text: '$',
  },
  {
    code: 'EUR',
    icon: faEuroSign,
    text: '€',
  },
  {
    code: 'RUR',
    icon: faRubleSign,
    text: '₽',
  },
  {
    code: 'GEL',
    text: '₾',
  },
  {
    code: 'KZT',
    text: '₸',
  },
];

export const getCurrencyIcon = (ISOCode: string) => {
  const currencyObj = CURRENCIES.find((el) => el.code === ISOCode);
  return currencyObj ? currencyObj.icon ? <FontAwesomeIcon icon={currencyObj.icon} /> : currencyObj.text : ISOCode;
};

export const getCurrencySymbol = (ISOCode: string) => {
  const currencyObj = CURRENCIES.find((el) => el.code === ISOCode);
  return currencyObj.text || ISOCode;
};

export const getCurrencyText = (ISOCode: string, type = 'full') => {
  const key = `currency:${ISOCode}.${type}`;
  return exists(key) ? t(key) : getCurrencySymbol(ISOCode);
};

const CurrencyIcon: React.FC<IProps> = (props) => {
  const { currencyISO = 'RUR' } = props;

  const currencyIcon = getCurrencyIcon(currencyISO);
  return <i>{currencyIcon}</i>;
};

export default CurrencyIcon;
