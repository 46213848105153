interface IPriceFormattedObject {
  integer: string;
  fractional: string;
  fractionalWithSeparator: string;
  full: string;
}

export const priceFormatedObject = (
  price: number,
  thousandsSeparator = ' ',
  decimalSeparator = ','
): IPriceFormattedObject => {
  const integer = Number(price)
    .toFixed(2)
    .split('.')[0]
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${thousandsSeparator}`);
  const fractional = Number(price).toFixed(2).split('.')[1];

  return {
    integer: integer,
    fractional: fractional,
    fractionalWithSeparator: `${decimalSeparator}${fractional}`,
    full: `${integer}${decimalSeparator}${fractional}`,
  };
};
