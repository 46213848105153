import * as React from 'react';
import { AppContext } from '@brainysoft/lk-components';
import device from './device';

export const useDisplayMode = () => {
  const { methods } = AppContext.useContainer();

  const willResize = () => {
    methods.setDisplayMode(device(window));
  };

  React.useEffect(() => {
    window.addEventListener('resize', willResize);
    willResize();
    return () => window.removeEventListener('resize', willResize);
  }, []);
};
