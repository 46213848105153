import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { LoadingSpinner } from '../LoadingSpinner';
import { useTranslation } from '../../utils/useTranslation';

export const ActiveCreditStatusLoading: React.FC = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  return (
    <div className={`${classes.wrapper} inWidget`}>
      <h2 className={classes.header}>{t(`active:loading`)}</h2>
      <LoadingSpinner size={8} margin={4} className={classes.loadingWrapper} />
    </div>
  );
};
