import React from 'react';

export function withStopPropagation(WrappedComponent: any) {
  const stopPropagation = (e) => e.stopPropagation();
  return function render(props: any) {
    return (
      <span onClick={stopPropagation}>
        <WrappedComponent {...props} />
      </span>
    );
  };
}
