import React from 'react';
import { FormItem, IColumnProps } from '../FormItem';
import { Field } from 'formik';
import { InputProps } from 'antd/lib/input';
import { getInputMask, InputMaskType, isNumerical, Masks } from './masks';
import { MaskedStyledInput } from '../MaskedStyledInput';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';

export interface IMaskedInput extends InputProps, IColumnProps {
  className?: string;
  mask: Masks;
  pattern?: string;
  enterButton?: React.FC;
  validateStatus?: any;
  loading?: boolean;
  initialValue?: unknown;
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  onKeyDown?(e): void;
  onChange?(e): void;
  focus?: boolean;
  disclaimer?: string;
  requiredErrorText?: string;
  shouldValidateOnChange?: boolean;
  //для случаев когда необходимо закрыть пользователю возможность редактирования, но не менять DOM чтобы поле по-прежнему было input и маунтилось в formik
  readonly?: boolean;
}

export const MaskedInput = (props: IMaskedInput) => {
  const { maskValidator }: InputMaskType = getInputMask(props.mask, props.pattern);

  const validate = (value) => {
    // 0 - валидное значение
    if (props.required && (value === undefined || value === null || value === ''))
      return props.requiredErrorText ?? 'required';

    if (typeof maskValidator === 'function') {
      const res = maskValidator(value, props.required);
      if (res) return res;
    }
  };

  return (
    <Field name={props.name} validate={validate}>
      {({ form, field, meta }) => <_MaskedInput form={form} field={field} meta={meta} {...props} />}
    </Field>
  );
};

const _MaskedInput = (props: IMaskedInput & { form: any; field: any; meta: any }) => {
  const classes = useThemeStyles('Form/MaskedInput');
  const { form, field, meta, ...restProps } = props;
  const { maskValidator, isComplete, maskParser, ...maskProps }: any = getInputMask(props.mask, props.pattern);

  const [inputValue, setInputValue] = React.useState<string | null | undefined>(undefined);
  const [mask, setMask] = React.useState(null);

  const ref = React.useRef();

  React.useEffect(() => {
    if (props.focus) {
      (ref.current as any).maskRef.el.input.focus();
    }
  }, [props.focus]);

  const initialValue =
    props.initialValue !== null && props.initialValue !== undefined
      ? String(props.initialValue)
      : meta.initialValue !== null && meta.initialValue !== undefined
      ? String(meta.initialValue)
      : isNumerical(props.mask)
      ? '0'
      : undefined;

  //setting an initial value
  React.useEffect(() => {
    if (meta.value !== undefined && meta.value !== null) {
      setInputValue(String(meta.value));
    } else {
      setInputValue(initialValue);
    }
  }, [meta.value]);

  const onKeyDown = (e) => (typeof props.onKeyDown == 'function' ? props.onKeyDown(e) : () => {});
  const onChange = (e) => (typeof props.onChange == 'function' ? props.onChange(e) : () => {});

  const getValue = React.useCallback((mask, maskType) => {
    if (mask) {
      if (maskType === 'email') {
        return (mask as any).value;
      } else if (maskType === 'mobilePhonePrefixed' || maskType === 'mobilePhone') {
        let typedValue = (mask as any).unmaskedValue;
        typedValue = typedValue === '' ? '7' : typedValue;
        return typedValue;
      } else {
        return isNumerical(maskType) ? Number((mask as any).unmaskedValue) : (mask as any).unmaskedValue;
      }
    }
  }, []);

  return (
    <FormItem {...restProps} meta={meta}>
      {!props.disabled && (
        <MaskedStyledInput
          meta={meta}
          validateStatus={props.validateStatus}
          id={field.name}
          name={field.name}
          value={inputValue}
          placeholder={props.placeholder}
          {...maskProps}
          autoFocus={props.autoFocus}
          disabled={props.disabled || props.readonly}
          onAccept={async (value, mask) => {
            await setMask(mask);
            await setInputValue(String(value));
            if (props.shouldValidateOnChange && props.mask !== 'email') {
              await form.setFieldValue(field.name, getValue(mask, props.mask));
            }
            if (typeof isComplete === 'function' && isComplete(getValue(mask, props.mask))) {
              await form.setFieldTouched(field.name, true);
            }
          }}
          onBlur={async () => {
            await form.setFieldValue(field.name, getValue(mask, props.mask));
            // await setFormikFieldValue(props.mask, field, form);
            form.setFieldTouched(field.name, true);
          }}
          onKeyDown={onKeyDown}
          onChange={onChange}
          ref={ref}
        />
      )}
      {!!props.disabled && (
        <div id={field.name} className={`ant-input ant-input-lg disabled static ${classes.input}`}>
          {inputValue}
        </div>
      )}
    </FormItem>
  );
};

MaskedInput.whyDidYouRender = true;
