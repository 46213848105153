import * as React from 'react';
import { useThemeStyles } from '../utils/useThemeStyles';

interface IIcon {
  inverted?: boolean;
}

export const ProfileIcon: React.FC<IIcon> = (props) => {
  const classes = useThemeStyles('Icons');

  const inverted = props.inverted ? 'inverted' : '';

  return (
    <span className={[classes.iconWrapper, inverted].join(' ')}>
      <svg width='32px' height='32px' viewBox='0 0 32 32' className={[classes.profileIcon, inverted].join(' ')}>
        <circle fill='none' stroke='#FFFFFF' strokeWidth='2' cx='16' cy='16' r='15' />
        <path
          fill='none'
          stroke='#FFFFFF'
          strokeWidth='1.5'
          d='M16,7.453c4.065,0,7.366,3.297,7.366,7.367
                    c0,4.065-3.301,7.362-7.366,7.362s-7.362-3.297-7.362-7.362C8.638,10.75,11.935,7.453,16,7.453z'
        />
        <path fill='none' stroke='#FFFFFF' strokeWidth='1.5' d='M12.61,21.352c-3.063,0.707-5.759,2.363-7.788,4.624' />
        <path fill='none' stroke='#FFFFFF' strokeWidth='1.5' d='M27.177,25.976c-2.024-2.26-4.724-3.917-7.788-4.624' />
      </svg>
    </span>
  );
};
