import * as React from 'react';

export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef<any>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    let id;
    function tick() {
      const callback = savedCallback.current;
      if (typeof callback === 'function') callback();
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
    }
    return () => clearInterval(id);
  }, [delay]);
};
