import { IMaskMixin } from 'react-imask';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { Icon } from '../../../../components/Icon';
import React from 'react';

export const MaskedStyledInput: React.ComponentType<any> = IMaskMixin(({ inputRef, ...props }) => {
  const classes = useThemeStyles('Form/MaskedInput');

  const autoFocus = props.autoFocus ? props.autoFocus : null;
  const disabled = props.disabled ? 'disabled' : '';
  delete (props as any).validator;

  const errorClassName = (props as any).meta?.touched && (props as any).meta?.error ? 'error' : '';
  const hasFeedback = (props as any).meta?.touched || (props as any).validateStatus;
  const validateStatus = (props as any).validateStatus ? (props as any).validateStatus : errorClassName;
  delete (props as any).meta;
  delete (props as any).validateStatus;

  return (
    <>
      <input
        autoComplete={'off'}
        autoFocus={autoFocus}
        className={`ant-input ant-input-lg ${disabled} ${classes.input}`}
        {...props}
        ref={inputRef}
      />

      {hasFeedback && validateStatus && (
        <span className={'ant-input-textarea-suffix'}>
          <span className={'ant-form-item-feedback-icon ant-form-item-feedback-icon-' + validateStatus}>
            {validateStatus === 'error' && <Icon type={'close-circle'} theme={'filled'} />}

            {validateStatus === 'success' && <Icon type={'check-circle'} theme={'filled'} />}
          </span>
        </span>
      )}
    </>
  );
});
